//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Drawer',
    props: [
      'name',
      'email',
      'show',
      'avatar',
    ],
    data() {
      return {
        searchText: null,
      }
    },
    computed:{
      profile_photo (){
        return this.$store.state.user.profile_photo
      }
    },
    methods: {
      search() {
        if (this.searchText == '') {
          this.$q.notify({
            message: 'Please enter a search text to search across the community.'
          });
        }
        this.$router.push({ path: '/search', query: { q: this.searchText } });
      },
      lo() {
        this.$store.dispatch('logout')
        .then((status) =>{
          if(status == 'logged out'){
            this.$store.commit('user/logout')
            this.$router.push('/login')
          }
        })
      },
  },
  created(){
    this.$store.dispatch('profile/getProfilePhoto',{user_id: this.$store.state.user.id, type: 'profile'})
  }
}
